














































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  ASSESSMENT_ERROR,
  ASSESSMENT_QUESTIONS,
  CURRENT_QUESTION_INDEX,
  GET_CURRENT_QUESTION,
  QUESTION_INDEX,
  SUBMIT_ASSESSMENT_ANSWERS,
  TENANT_INFO,
  TENANT_INFO_LOADING,
  UPDATE_ASSESSMENT_RECORD
} from "@/store/modules/candidates/constants";
import {
  AssessmentAnswersPayload,
  AssessmentErrorTypes,
  AssessmentRecord
} from "@/store/modules/candidates/interfaces";
import AssessmentError from "@/components/assessments/AssessmentError.vue";
import { AppRoutes } from "@/interfaces";

export default Vue.extend({
  name: "AssessmentBody",
  components: { AssessmentError },
  data() {
    return {
      answers_form: false,
      required_rule: [(value: string) => !!value || "Field is required"],
      answers_rubric_options: [
        {
          label: "Agree Strongly",
          value: 7
        },
        {
          label: "Agree",
          value: 6
        },
        {
          label: "Agree Slightly",
          value: 5
        },
        {
          label: "Neither Agree Nor Disagree",
          value: 4
        },
        {
          label: "Disagree Slightly",
          value: 3
        },
        {
          label: "Disagree",
          value: 2
        },
        {
          label: "Disagree Strongly",
          value: 1
        }
      ]
    };
  },
  computed: {
    AssessmentErrorTypes() {
      return AssessmentErrorTypes;
    },
    ...mapGetters("candidate", {
      tenant_info: TENANT_INFO,
      get_current_question: GET_CURRENT_QUESTION,
      tenant_loading: TENANT_INFO_LOADING
    })
  },
  watch: {
    async tenant_loading(n) {
      if (
        !n &&
        this.tenant_info.assessment_questions.assessmentProgress
          .assessmentComplete
      )
        await this.$router.push(
          `${AppRoutes.Candidae.PERSONALITY_ASSESSMENT_REPORT}${this.tenant_info.details.id}`
        );
    }
  },
  async mounted() {
    if (
      this.tenant_info.assessment_questions.assessmentProgress
        .assessmentComplete
    ) {
      await this.$router.push(
        AppRoutes.Candidae.PERSONALITY_ASSESSMENT_SUCCESS
      );
    }
  },
  methods: {
    ...mapMutations("candidate", {
      update_question_index: QUESTION_INDEX,
      update_assessment_record: UPDATE_ASSESSMENT_RECORD,
      update_current_question_index: CURRENT_QUESTION_INDEX,
      assessment_error: ASSESSMENT_ERROR
    }),
    ...mapActions("candidate", {
      submit_assessment_answers: SUBMIT_ASSESSMENT_ANSWERS,
      fetch_assessment_questions: ASSESSMENT_QUESTIONS
    }),
    async submit_questions() {
      // Payload for sending API request
      const answersPayload: AssessmentAnswersPayload[] =
        this.tenant_info.assessment_record.map((val: AssessmentRecord) => {
          if (val.answerNumber) {
            return {
              questionNumber: val.questionNumber,
              answerNumber: val.answerNumber
            };
          }
        });
      // Submit answers
      await this.submit_assessment_answers({
        answers: answersPayload,
        question_index: this.tenant_info.details.question_index + 4
      });
      // Fetch more questions
      await this.fetch_assessment_questions();
      // Increment question label
      this.update_current_question_index(
        this.tenant_info.details.question_index + 4
      );
    },
    all_ans_received(): boolean {
      const answered = this.tenant_info.assessment_record.find(
        (val: AssessmentRecord) => val.answerNumber <= 0
      );
      return !!answered;
    }
    // async next_question() {
    //   // Update locally questions record
    //   this.update_questions_locally();
    //   // If current set of questions completed => fetch more
    //   if (
    //     this.tenant_info.question_index !== 0 &&
    //     (this.tenant_info.question_index + 1) % 4 === 0
    //   ) {
    //     // Send answers to backend
    //     await this.submit_questions();
    //     // Fetch more questions
    //     await this.fetch_assessment_questions();
    //     // Reset question index, cuz only 4 questions at a time
    //     this.update_question_index(0);
    //   } else {
    //     // Increment question index
    //     this.update_question_index(this.tenant_info.question_index + 1);
    //   }
    //   // Increment question label
    //   this.update_current_question_index(
    //     this.tenant_info.details.question_index + 1
    //   );
    //   if (
    //     this.tenant_info.assessment_questions.assessmentProgress
    //       .assessmentComplete
    //   ) {
    //     await this.$router.push("/candidate/assessments/success");
    //   }
    // },
    // previous_question() {
    //   this.update_question_index(this.tenant_info.question_index - 1);
    //   if (this.tenant_info.details.question_index > 1)
    //     this.update_current_question_index(
    //       this.tenant_info.details.question_index - 1
    //     );
    // },
    // update_questions_locally() {
    //   const payload: AssessmentRecord = {
    //     question: this.get_current_question.question,
    //     questionNumber: this.get_current_question.questionNumber,
    //     answerNumber: this.get_current_question.answerNumber
    //   };
    //   this.update_assessment_record(payload);
    // },
  }
});
